import {getDateISO} from "@/shared/dateFormat";

export interface HdDepartureListFiltersValues {
    id: string[]
    createdAt: string[],
    refundDeadline: string[],
    status: string[],
    eurSum: string[],
    aedSum: string[],
    departureCost: string[],
    note: string[],
    responsibleUser: string[],
    perPage?: number,
}

export function getEmptyHdDepartureListFiltersValues() : HdDepartureListFiltersValues{
    return {
        id: [],
        createdAt: [],
        refundDeadline: [],
        status: [],
        eurSum: ['', ''],
        aedSum: ['', ''],
        departureCost: ['', ''],
        note: [''],
        responsibleUser: [''],
    }
}

export interface HdDepartureListFiltersValuesPayload {
    page: number,
    per_page?: number,
    start_created_at?: string,
    stop_created_at?: string,
    responsible_search?: string,
    min_delivery_cost?: string,
    max_delivery_cost?: string,
    status?: string,
    note_search?: string,
    min_aed_sum?: string,
    max_aed_sum?: string,
    min_eur_sum?: string,
    max_eur_sum?: string,
    courier_search?: string,
    ids?: number[]
}

export interface HdDepartureSearchRequest {
    pinned_ids: number[]
    search_id: number
}

export function getEmptyHdDepartureListFiltersValuesPayload() : HdDepartureListFiltersValuesPayload {
    return {
        page: 1,
    }
}

export function mapHdDepartureListFiltersValuesPayload(filtersValues: HdDepartureListFiltersValues, page: number) {
    let params: HdDepartureListFiltersValuesPayload = {
        page: page,
    }

    if (filtersValues.id.length) {
        params.ids = filtersValues.id.map((e) => parseInt(e))
    }

    if (filtersValues.createdAt.length) {
        params.start_created_at = getDateISO(filtersValues.createdAt[0])
        params.stop_created_at = getDateISO(filtersValues.createdAt[1])
    }

    if (filtersValues.eurSum.length && filtersValues.eurSum[0] != '') {
        params.min_eur_sum = filtersValues.eurSum[0] as any
        params.max_eur_sum = filtersValues.eurSum[1] as any
    }

    if (filtersValues.aedSum.length && filtersValues.aedSum[0] != '') {
        params.min_aed_sum = filtersValues.aedSum[0] as any
        params.max_aed_sum = filtersValues.aedSum[1] as any
    }

    if (filtersValues.departureCost.length && filtersValues.departureCost[0] != '') {
        params.min_delivery_cost = filtersValues.departureCost[0] as any
        params.max_delivery_cost = filtersValues.departureCost[1] as any
    }

    if (filtersValues.status.length) {
        params.status = filtersValues.status?.join(',')
    }

    if (filtersValues.note.length && filtersValues.note[0] != '') {
        params.note_search = filtersValues.note[0]
    }

    if (filtersValues.responsibleUser.length && filtersValues.responsibleUser[0] != '') {
        params.responsible_search = filtersValues.responsibleUser[0]
    }

    if (filtersValues.perPage) {
        params.per_page = filtersValues.perPage
    }
    
    return params
}

export function downloadBase64File(base64Data: string, filename: string) {

    const base64Data2 = base64Data.includes('base64') ? base64Data.split('base64,')[1] : base64Data

    // Создаем Blob из base64 строки
    const blob = new Blob([Uint8Array.from(atob(base64Data2), c => c.charCodeAt(0))], { type: 'application/octet-stream' });

    // Создаем временный URL
    const url = URL.createObjectURL(blob);

    // Создаем ссылку
    const link = document.createElement('a');
    link.href = url;
    link.download = filename; // Устанавливаем имя файла

    // Имитация клика на ссылку
    document.body.appendChild(link);
    link.click();

    // Удаляем ссылку после скачивания
    document.body.removeChild(link);

    // Освобождаем временный URL
    URL.revokeObjectURL(url);
}